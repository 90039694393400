import React from "react"
import styles from "./styles.module.css"
import cx from "classnames"
import Typography from "src/components/shared/Typography"

const Title = ({ children, className }) => (
  <Typography.h1 className={cx(styles.Title, className)}>
    {children}
  </Typography.h1>
)

export default Title
