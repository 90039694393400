import React from "react"
import { Input, TextArea } from "src/components/shared/Form"
import Typography from "src/components/shared/Typography"
import styles from "./styles.module.css"
import Button from "../Button"

const ContactForm = () => (
  <div>
    <Typography.h1 className={styles.Header}>Contactez-nous</Typography.h1>
    <Typography.p primary className={styles.Paragraph}>
      Une question ? Un rendez-vous à prendre ? Remplissez le questionnaire
      ci-dessous :
    </Typography.p>

    <form name="contact" method="POST" action="/merci" data-netlify="true">
      <label htmlFor="name">
        <Input
          id="name"
          name="name"
          type="text"
          required
          placeholder="Votre nom"
        />
      </label>
      <label htmlFor="mail">
        <Input
          id="mail"
          name="mail"
          type="email"
          required
          placeholder="Votre email"
        />
      </label>
      <label htmlFor="message">
        <TextArea
          id="message"
          name="message"
          required
          placeholder="Votre message"
        />
      </label>
      <input type="hidden" name="form-name" value="contact" />
      <Button type="submit">Envoyer</Button>
    </form>
  </div>
)

export default ContactForm
