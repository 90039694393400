import React, { useEffect } from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import styles from "./reservation.styles.module.css"
import Title from "../components/shared/Title"
import ContactForm from "src/components/shared/ContactForm"

const ReservationPage = () => {
  useEffect(() => {
    if (typeof window === "undefined") return null
    const simplyBookWidget = require("../../static/simplybook/widget")
    const simplyBookInitialize = require("../../static/simplybook/init")
  })

  return (
    <Layout>
      <SEO title="Reservation — Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />
      <Inner>
        <Title>Réservation</Title>
        <section className={styles.Reservation}>
          <span role="img" aria-label="information :">
            ℹ️
          </span>{" "}
          Le module de réservation peut mettre plus d'une minute à charger
        </section>
        {/* DO NOT REMOVE ID */}
        <div id="simplybook"></div>
      </Inner>

      <Inner small>
        <ContactForm />
      </Inner>
    </Layout>
  )
}

export default ReservationPage
