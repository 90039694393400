var widget = new window.SimplybookWidget({
  widget_type: "iframe",
  url: "https://turtletonic.simplybook.it",
  theme: "dainty",
  theme_settings: {
    timeline_show_end_time: "1",
    sb_base_color: "#00757e",
    secondary_color: "#e4ebf5",
    sb_text_color: "#a1a1a1",
    display_item_mode: "block",
    body_bg_color: "#ffffff",
    sb_background_image: "",
    dark_font_color: "#293b36",
    light_font_color: "#ffffff",
    sb_company_label_color: "#ffffff",
    sb_cancellation_color: "#e66687",
    hide_img_mode: "0",
  },
  timeline: "grid_week",
  datepicker: "inline_datepicker",
  is_rtl: false,
  app_config: { predefined: [] },
})
